import React from 'react'
import BoxStaticPageList from '../../../src/common/components/BoxStaticPageList/BoxStaticPageList'
import LayoutLanding from '../../../src/common/components/layouts/layoutLanding/LayoutLanding'
import { locale, t } from '../../../src/common/i18n'
import { getFullCompany } from '../../../src/common/utils/getCompany'

const ImageAreaProfesional =
  'https://media.rodi.es/landings/camion/area-profesionales-camion-rodi.jpg'
const ImageAsistenciaFlotas =
  'https://media.rodi.es/landings/camion/asistencia-flotas-camion-remolque.jpg'
const ImageAsistenciaRemolque =
  'https://media.rodi.es/landings/camion/asistencia-rodi-24h-camion-remolque.jpg'
const ImageEjes =
  'https://media.rodi.es/landings/camion/ejes-camion-remolque.jpg'
const ImageFrenos =
  'https://media.rodi.es/landings/camion/mantenimiento-frenos-camion-remolque.jpg'
const ImageNeumaticos =
  'https://media.rodi.es/landings/camion/neumaticos-insdustriales-camion.jpg'
const ImageRevision =
  'https://media.rodi.es/landings/camion/revision-camion-remolques.jpg'
const ImageSistemaSuspension =
  'https://media.rodi.es/landings/camion/sistema-suspension-camion.jpg'

interface Props {
  styles: { [k: string]: string }
}

const sectionsEs = [
  {
    title: 'SERVICIOS DE MECÁNICA',
    items: [
      {
        title: 'Revisión de Remolques',
        description: `Realizamos una completa revisión visual y de fugas del remolque. 
        Disponemos, además, de servicio de diagnosis autorizado por los fabricantes. 
        Más información aquí. `,
        img: ImageRevision,
        route: 'camion.revisiones',
        external_url: false,
      },
      {
        title: 'Frenos para Remolques',
        description: `Nos ocupamos de verificar el estado de los frenos de tu camión y 
        remolque. Realizamos cambio de pastillas y discos. Más información aquí.`,
        img: ImageFrenos,
        route: 'camion.frenos',
        external_url: false,
      },
      {
        title: 'Sistema de Suspensión',
        description: `Las suspensiones de tu camión se resienten con cada kilómetro. 
        En Rodi podemos verificar su estado y realizar un correcto mantenimiento. 
        Más información aquí.`,
        img: ImageSistemaSuspension,
        route: 'camion.suspension',
        external_url: false,
      },
      {
        title: 'Ejes de Remolque',
        description: `Somos servicio autorizado por los principales fabricantes de ejes para 
        camión y remolque. Realizamos tramitación de garantías y recambios. Más información aquí.`,
        img: ImageEjes,
        route: 'camion.ejes',
        external_url: false,
      },
    ],
  },
  {
    title: 'OTROS SERVICIOS',
    items: [
      {
        title: 'Asistencia 24 horas',
        description: `En Rodi disponemos de vehículos especializados para la asistencia en 
        carretera de flotas de camión. Más información aquí`,
        img: ImageAsistenciaRemolque,
        route: 'camion.asistencia',
        external_url: false,
      },
      {
        title: 'Neumáticos Industriales',
        description: `Tenemos una amplia selección de neumáticos industriales con las 
        mejores marcas del mercado. Más información aquí.`,
        img: ImageNeumaticos,
        route: 'camion.neumaticos',
        external_url: false,
      },
    ],
  },
  {
    title: 'ACCESO A PROFESIONALES',
    items: [
      {
        title: 'Gestión de Flotas',
        description: `Podemos ayudarte a ahorrar hasta un 15% en el mantenimiento de tu flota de vehículos. 
        Disponemos de una completa herramienta de control de flotas. Más información aquí. `,
        img: ImageAsistenciaFlotas,
        route: 'camion.gestion',
        external_url: false,
      },
      {
        title: 'Acceso a Profesionales',
        description: `En nuestra web encontrarás un apartado dedicado a profesionales donde 
        puedes consultar el estado de toda tu flota de vehículos profesionales. Más información aquí.`,
        img: ImageAreaProfesional,
        external_url: true,
        route: 'https://www.rodi.es/acceso-profesionales/',
      },
    ],
  },
]

const sectionCa = [
  {
    title: 'SERVEIS DE MECÀNICA',
    items: [
      {
        title: 'Revisió de Remolcs',
        description: `Realitzem una completa revisió visual i de fuites del remolc. 
        Disposem, a més, de servei de diagnosi autoritzat pels fabricants. Més informació aquí.`,
        img: ImageRevision,
        route: 'camion.revisiones',
        external_url: false,
      },
      {
        title: 'Frens per a Remolcs',
        description: `Ens ocupem de verificar l'estat dels frens del teu camió i remolc. 
        Realitzem canvi de pastilles i discos. Més informació aquí.`,
        img: ImageFrenos,
        route: 'camion.frenos',
        external_url: false,
      },
      {
        title: 'Sistema de Suspensió',
        description: `Les suspensions del teu camió es ressenten amb cada quilòmetre. 
        A Rodi podem verificar el seu estat i realitzar un correcte manteniment. Més informació aquí.`,
        img: ImageSistemaSuspension,
        route: 'camion.suspension',
        external_url: false,
      },
      {
        title: 'Eixos de Remolc',
        description: `Som servei autoritzat pels principals fabricants d'eixos per a camió i remolc. 
        Realitzem tramitació de garanties i recanvis. Més informació aquí.`,
        img: ImageEjes,
        route: 'camion.ejes',
        external_url: false,
      },
    ],
  },
  {
    title: 'ALTRES SERVEIS',
    items: [
      {
        title: 'Assistència 24 hores',
        description: `A Rodi disposem de vehicles especialitzats per  l'assistència a carretera de 
        flotes de camió. Més informació aquí.`,
        img: ImageAsistenciaRemolque,
        route: 'camion.asistencia',
        external_url: false,
      },
      {
        title: 'Pneumàtics Industrials',
        description: `Tenim una àmplia selecció de pneumàtics industrials amb les millors 
        marques del mercat. Més informació aquí.`,
        img: ImageNeumaticos,
        route: 'camion.neumaticos',
        external_url: false,
      },
    ],
  },
  {
    title: 'ACCÉS A PROFESSIONALS',
    items: [
      {
        title: 'Gestió de Flotes',
        description: `Podem ajudar-te a estalviar fins a un 15% en el manteniment de la teva
         flota de vehicles. Disposem d’una completa eina de control de flotes. Més informació aquí.`,
        img: ImageAsistenciaFlotas,
        route: 'camion.gestion',
        external_url: false,
      },
      {
        title: 'Accés a Professionals',
        description: `A la nostra web trobaràs d'un apartat dedicat a professionals on pots 
        consultar l'estat de tots els teus vehicles professionals. Més informació aquí.`,
        img: ImageAreaProfesional,
        external_url: true,
        route: 'https://www.rodi.es/acceso-profesionales/',
      },
    ],
  },
]

const CamionCa = ({ styles }: Props) => (
  <LayoutLanding
    title="Serveis per al teu camió"
    descriptionCTA={t('landing_camion.descripcion_cta')}
    textButtonCTA={t('ficha-taller.appointment')}
    ctaLink={{
      cta: 'talleres_mecanicos',
      cta_es: 'LOCALIZA TU TALLER',
      cta_ca: 'LOCALITZA EL TEU TALLER',
    }}>
    <React.Fragment>
      <p>
        A <strong>Rodi Motor Services</strong> disposem de serveis de
        <strong>
          manteniment per a vehicles logístics com camions i remolcs
        </strong>
        . Oferim solucions de <strong>mecànica</strong> i
        <strong>assistència a professionals </strong>
        com: canvi de pneumàtics, revisió de camió i remolc, assistència a
        carretera 24h...
      </p>
      <p>
        A Rodi trobaràs una <strong>amplia xarxa de tallers </strong>y una
        solució de manteniment adaptada a les necessitats de la teva empresa i
        del teu camió.
      </p>
      <p>
        A continuació, disposes de tots els serveis que oferim. Dins de cada
        secció, expliquem què és cada element i la seva importància per a què el
        vehicle es mantingui sempre en un estat òptim per a la seva circulació.
      </p>
      {sectionCa.map(section => (
        <BoxStaticPageList title={section.title} items={section.items} />
      ))}
    </React.Fragment>
  </LayoutLanding>
)

const CamionEs = ({ styles }: Props) => (
  <LayoutLanding
    title="Servicios para tu camión"
    descriptionCTA={t('landing_camion.descripcion_cta')}
    textButtonCTA={t('ficha-taller.appointment')}
    ctaLink={{
      cta: 'talleres_mecanicos',
      cta_es: 'LOCALIZA TU TALLER',
      cta_ca: 'LOCALITZA EL TEU TALLER',
    }}>
    <React.Fragment>
      <p>
        En <strong>{getFullCompany()}</strong> disponemos de servicios de
        <strong>
          mantenimiento para vehículos logísticos como camiones y remolques
        </strong>
        . Ofrecemos soluciones de <strong>mecánica</strong> y
        <strong>asistencia a profesional </strong>
        como<strong>: </strong>cambio de neumáticos<strong>, </strong>revisión
        del camión y su remolque<strong>, </strong>asistencia en carretera 24h…
      </p>
      <p>
        En Rodi encontrarás una <strong>amplia red de talleres</strong>  y una
        solución de mantenimiento adaptada a las necesidades de tu empresa y de
        tu camión.
      </p>
      <p>
        A continuación, dispones de todos los servicios que ofrecemos. Dentro de
        cada sección, explicamos qué es cada elemento y su importancia para que
        el vehículo se mantenga siempre en un estado óptimo para su circulación.
      </p>
      {sectionsEs.map(section => (
        <BoxStaticPageList
          key={section.title}
          title={section.title}
          items={section.items}
        />
      ))}
    </React.Fragment>
  </LayoutLanding>
)

const CamionContents = ({ ...props }: Props) =>
  locale === 'es' ? <CamionEs {...props} /> : <CamionCa {...props} />

export default CamionContents
